import React from "react";
import PrivacyIcon from "../Images/Privacy_icon.svg";
import "./Privacy.scss";
import Animation from "../Animation/Animation";

export const Privacy = () => {
  return (
    <div
      className="justify-center pt-10 pb-14"
      // style={{
      //   background:
      //     "linear-gradient(90deg, #E6D7FF 0%, rgba(230, 215, 255, 0.53) 0.01%, rgba(155, 141, 184, 0.73) 100.4%, rgba(75, 62, 108, 0) 70%)",
      // }}
    >
      <div className="flex-col">
        {/* <img
          className=" "
          src={PrivacyIcon}
          alt="Privacy icon"
          style={{
            width: "70px",
            //   display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        /> */}
        <Animation/>

        <div
          className=" text-white text-[46px] font-geo wow fadeInUp font-semibold  privacy "
        >
          Your privacy is our responsibility
        </div>
      </div>
      <div className="flex justify-center font-medium font-sans wow fadeInUp lg:mx-36">
        {" "}
        <div className="text-gray-200 text-center text-xl  mt-4 mx-4">
        We believe that trust is paramount in a relationship. We do not own or sell your data, and we most certainly do not bank on advertising-based business models. Our revenue model is based exclusively on licensing and expertise charges.

        </div>
      </div>
    </div>
  );
};
