import React, { useEffect } from "react";
import { FaClinicMedical, FaHandHoldingMedical } from "react-icons/fa";
import { GiHealthPotion } from "react-icons/gi";
import { MdHealthAndSafety } from "react-icons/md";
import WOW from "wow.js";

const AiHealth = () => {
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  const cardsData = [
    {
      logo: <MdHealthAndSafety fill="#ffffff" size={60} />,
      title: "Healthcare Providers",
      description:
        "Our healthcare software development services streamline tasks, speed up diagnosis, ensure data safety, and enhance decision-making efficiency, fostering high patient satisfaction. We tailor hospital management software to match your hospital's unique operations and needs.",
    },
    {
      logo: <GiHealthPotion fill="#ffffff" size={60} />,
      title: "Pharmaceutical Companies",
      description:
        "We assist pharmaceutical firms in managing their intricate processes through customized software solutions like Electronic Lab Notebooks (ELN), Laboratory Information Management Systems (LIMS), Supply Chain Management (SCM) Systems, Customer Relationship Management (CRM) Systems, Business Analytics, and Intelligence Solutions.",
    },
    {
      logo: <FaHandHoldingMedical fill="#ffffff" size={60} />,
      title: "Medical Device Manufacturers",
      description:
        "We support medical device manufacturers by creating, testing, and maintaining specialized software solutions for their products, all while ensuring compliance with regulations.",
    },
    {
      logo: <FaClinicMedical fill="#ffffff" size={60} />,
      title: "Healthcare Startups",
      description:
        "Leveraging our expertise and understanding of regulatory requirements, we aid healthcare startups in launching market-ready products swiftly and effectively in the health-tech sector.",
    },
  ];

  return (
    <div className="section pb-10 mt-4 font-sans" id="diffrence" style={{ textAlign: "justify", textJustify: "auto" }}>
      <div>
        <p className="text-3xl lg:text-4xl text-[#EF3B3B] font-bold  font-geo text-center wow fadeInUp pt-4 mx-10">
          Made-to-order AI Healthcare Solutions for the complete Healthcare Ecosystem
        </p>
        <div className="flex flex-wrap justify-center mt-24 wow fadeInUp mx-10 lg:mx-24">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-5">
            {cardsData.map((card, index) => (

                <div
                  className="group bg-white bg-opacity-10 shadow-2xl rounded-lg ring-1 ring-gray-500 transform hover:scale-105 transition-transform duration-500"
                  style={{ borderRadius: "20px" }}
                >
                  <div className="flex justify-center text-center p-6">
                    {card.logo}
                  </div>
                  <div className="px-4 py-2">
                    <h3 className="text-2xl font-geo  text-center text-[#EF3B3B] font-bold mb-2">
                      {card.title}
                    </h3>
                    <p className="text-[#ababab] text-center font-semibold text-md p-3">
                      {card.description}
                    </p>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiHealth;
