import React, { useState } from "react";
import red from "../Images/red.svg";
import blue from "../Images/blue.svg";
import purple from "../Images/purple.svg";
import green from "../Images/green.svg";
import navy from "../Images/Navy.svg";
import yellow from "../Images/yellow.svg";
import defaults from "../Images/Akka.png";
import Futures from "../Images/Future.png"

export const ValuesAi = () => {
    const [hoveredTitleIndex, setHoveredTitleIndex] = useState(0);

    const titles = [
        {
            logo: blue,
            title: "AI-enabled Provider Transcripts",
            Descriptions: "Our AI services enhance customer understanding by analyzing real-time data on behavior, enabling personalized interactions and anticipating customer needs for better business outcomes."
        },
        {
            logo: green,
            title: "Smarter Processes",
            Descriptions: "Our services help you make the most of your resources and improve decision-making by identifying patterns and trends in your data. AI/ML helps streamline and accelerate your day-to-day business processes."
        },
        {
            logo: yellow,
            title: "Intuitive Solutions",
            Descriptions: "By studying how customers use your products, we can help you improve designs and develop smart, user-friendly solutions with the features they truly want making their experience smooth and enjoyable."
        },
        {
            logo: red,
            title: "Enhanced Customer Support",
            Descriptions: "We help you to upgrade your customer support with intelligent virtual assistants that provide human-like, context-aware assistance that scales to meet demand."
        },
        {
            logo: navy,
            title: "Personalized For Your Business",
            Descriptions: "Our AI solutions analyze customer behavior to uncover pain points and customize solutions for process optimization, helping you work better and reach your full potential."
        },
        {
            logo: purple,
            title: "Data-Driven Growth",
            Descriptions: "We help organizations capitalize on the value of their data by providing powerful insights. This data-driven approach leads to better decision-making and sustainable business growth."
        }
    ];

    return (
        <div className="mt-10 font-sans mb-5" id="Values">
            <p className="flex justify-center text-4xl font-sans sm:text-4xl lg:text-5xl text-[#EF3B3B] font-semibold text-start wow fadeInUp mt-6"></p>
            <div className="lg:mx-24">
                <div className="lg:text-5xl  font-geo text-3xl font-bold text-center wow fadeInUp text-[#EF3B3B] mt-6 mb-5">
                    Values We Bring with our Artificial Intelligence Development Services
                </div>
            </div>
            <div className="" style={{ backgroundImage: `url(${Futures})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="p-10 flex mx-20">
                    <div>
                        <img src={defaults} alt="" className="" />
                    </div>
                    <div className="flex items-start justify-between w-full">
                        <div className="">
                            {titles.map((text, index) => (
                                <div
                                    key={index}
                                    className="flex gap-4 -ml-5 mt-4"
                                    onMouseEnter={() => setHoveredTitleIndex(index)}
                                    onMouseLeave={() => setHoveredTitleIndex(0)}
                                >
                                    <div className="bg-gray-200 hover:bg-[#EF3B3B] text-black hover:text-white p-2 gap-2 w-full flex items-center rounded-l-none pr-6 rounded-r-full mt-2">
                                        <div>
                                            <img src={text.logo} alt="" className="lg:h-8 sm:h-14 h-24" />
                                        </div>
                                        <div>
                                            <div className="text-left text-xl font-semibold">
                                                {text.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="ml-10 flex-1  flex items-center p-4 text-left lg:mt-36 mt-1">
                     
                            {hoveredTitleIndex !== null && (
                                <div className="">
                              <div className="text-left text-4xl font-geo font-black mb-5 text-[#EF3B3B]">
                               {titles[hoveredTitleIndex].title}
                               </div>
                                <div className="text-white text-xl">
                                    {titles[hoveredTitleIndex].Descriptions}
                                </div>
                                </div>
                                  
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
