import React, { useState } from "react";
import { Modal } from "antd";
import { IconPlus } from "@tabler/icons-react";
import ignite from "../Images/brandbird.png";
import intergrate from "../Images/brandbird2.png";
import Indemnify from "../Images/brandbird3.png";
import Individualize from "../Images/brandbird6.png";
import "./Cards.scss";

export const Cards = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [modalsVisible, setModalsVisible] = useState(new Array(4).fill(false));

  const data = [
    {
      text: "Solutions abound here",
      imageSrc: (
        <IconPlus className={`icon ${isHovered ? "rotate" : ""}`} size={20} style={{ color: "#000000" }} />
      ),
    },
    {
      text: "Dive into industries",
      imageSrc: (
        <IconPlus className={`icon ${isHovered ? "rotate" : ""}`} size={20} style={{ color: "#000000" }} />
      ),
    },
    {
      text: "Steer into your next quest",
      imageSrc: (
        <IconPlus className={`icon ${isHovered ? "rotate" : ""}`} size={20} style={{ color: "#000000" }} />
      ),
    },
  ];

  const showModal = (index) => {
    const updatedModals = [...modalsVisible];
    updatedModals[index] = true;
    setModalsVisible(updatedModals);
  };

  const hideModal = (index) => {
    const updatedModals = [...modalsVisible];
    updatedModals[index] = false;
    setModalsVisible(updatedModals);
  };

  const modal = [
    {
      text: "Solutions abound here",
      description: "Your journey doesn't end here!.",
      sideHeader: "Explore our comprehensive range of services and redefine satisfaction.",
      points: [
        "UX & UI Design",
        "Web Development",
        "Product Development",
        "Application Development",
        "Development and DevOps",
        "Content Management Systems",
        "LLM Integration (Chat GPT), AI",
        "Data Analytics and Business Intelligence",
      ],
    },
    {
      text: "Dive into industries",
      description: "Bold ideas need fertile ground.",
      sideHeader: "Explore our target industries – the fertile soil where innovation takes root and grows.",
      points: [
        "Automobile",
        "Real Estate",
        "Manufacturing",
        "Telecommunication",
        "Travel and Hospitality",
        "On-demand Solutions",
        "Retail and E-commerce",
        "Logistics and Distribution",
      ],
    },
    {
      text: "Steer into your next quest",
      description: "Bravely go where no one has gone before, fueled by the intelligence of tomorrow – Your Next Quest, powered by Artificial Intelligence",
      sideHeader: "From intelligent automation to advanced machine learning solutions, we specialize in crafting bespoke applications that elevate efficiency and drive innovation. Right now, we're cooking up something extraordinary, a Virtual Care Assistant application tailored for Hospitals, precisely designed to meet and exceed US standards. Feel the power of AI to transform your ideas into intelligent, forward-thinking digital solutions with our expert development team.",
      points: [],
    },
  ];

  const cardData = [
    {
      name: "Ignite",
      description: "Forge a digital revolution that not only reshapes your brand but also cranks up customer loyalty, carving out an unbeatable digital odyssey.",
      img: ignite,
    },
    {
      name: "Integrate",
      description: "Sync systems to create a collaborative haven effortlessly, boost collaboration, and soar in operational efficiency.",
      img: intergrate,
    },
    {
      name: "Indemnify",
      description: "Bulletproof your digital fortress, shielding sensitive data, instilling stakeholder confidence, and laying the bedrock for unstoppable business expansion.",
      img: Indemnify,
    },
    {
      name: "Individualize",
      description: "Shape unique journeys that deeply connect with every user cultivating unmatched customer satisfaction through bespoke experiences.",
      img: Individualize,
    },
  ];

  return (
    <div className="mt-10" id="nearfuture">
      <p className="flex justify-center text-4xl font-sans sm:text-4xl lg:text-5xl text-[#EF3B3B] font-semibold text-start wow fadeInUp mt-6">
        <span className="flex justify-center flex-col font-geo font-bold items-center">
          <svg
            width="129"
            height="27"
            viewBox="0 0 129 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mb-6"
          >
            <rect width="4" height="27" fill="white" />
            <rect x="25" width="4" height="27" fill="white" />
            <rect x="50" width="4" height="27" fill="white" />
            <rect x="75" width="4" height="27" fill="#C7C7C7" />
            <rect x="100" width="4" height="27" fill="white" />
            <rect x="125" width="4" height="27" fill="white" />
          </svg>
          The Near Future
        </span>
      </p>
      <div className=" p-4 flex justify-center text-xl font-sans text-gray-400 text-start mt-3 font-semibold leading-relaxed lg:mb-6 wow fadeInUp mb-4">
        We equip you to lead in the digital era with our transformative advantages.
      </div>
      <div className="flex justify-center gap-5 flex-wrap font-sans">
        {cardData.map((content, index) => (
          <div className="card" key={index} style={{ backgroundImage: `url(${content.img})` }}>
            <div className="card-content h-full">
              <h2 className="card-title mb-10 font-bold text-xl">{content.name}</h2>
              <p className="card-body font-medium mt-3">{content.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col lg:flex-row justify-around lg:mb-10 mb-1 mt-10 mx-3" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {data.map((item, index) => (
          <div
            key={index}
            className="flex-item p-4 text-center flex flex-col text-white font-semibold font-sans"
          >
            <div className="flex flex-col justify-center items-center mb-5">
              <p className="mb-4">{item.text}</p>
              <div
                className="bg-white rounded-full h-8 w-8 flex justify-center items-center"
                onClick={() => showModal(index)}
              >
                {item.imageSrc}
              </div>
            </div>
          </div>
        ))}
      </div>
      {modal.map((item, index) => (
        <Modal
          key={index}
          width={1200}
          visible={modalsVisible[index]}
          onCancel={() => hideModal(index)}
          footer={null}
        >
          <div className="font-sans">
            <div className="p-4 text-center">
              <div className="text-5xl font-bold text-center text-[#EF3B3B] pt-3">
                {item.text}
              </div>
              <div className="text-[#646464] font-semibold text-2xl">
                {item.sideHeader}
              </div>
            </div>
            {index !== 2 && 
            <div className="flex flex-col sm:flex-row justify-center gap-9 text-xl mt-2 text-white">
              <div>
                <ul className="list-disc list-inside">
                  {item.points.slice(0, 4).map((point, idx) => (
                    <li key={idx}>{point}</li>
                  ))}
                </ul>
              </div>
              <div>
                <ul className="list-disc list-inside">
                  {item.points.slice(4).map((point, idx) => (
                    <li key={idx}>{point}</li>
                  ))}
                </ul>
              </div>
            </div>}
            <div className="text-2xl font-semibold text-center p-4 italic"
              style={{
                background:
                  "-webkit-linear-gradient(153deg, rgba(0,240,255,1) 0%, rgba(82,0,255,1) 52%, rgba(255,45,247,1) 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}>
              {item.description}
            </div>
          </div>
        </Modal>
      ))}
    </div>
  );
};
