import React, { useRef, useState } from "react";
import ContactBg from "../Images/BG.png";
import emailjs from "emailjs-com";
import "./Contact.scss";
import ContactImg from "../Animation/Contactimg";
import { segment } from "../../segment";

export const Contact = () => {
  const [selectedOption, setSelectedOption] = useState("Hire a Talent");
  const form = useRef();
  const [status, setStatus] = useState({});
  
  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs
      .sendForm(
        "service_dy3bq8n",
        "template_yrqixua",
        form.current,
        "h1ufPd8Q7H0sfseRs"
      )
      .then(
        (result) => {
          console.log(result.text);
          setStatus({ success: true, message: "Email sent successfully!" });
          form.current.reset(); // Reset the form after success
        },
        (error) => {
          console.log(error.text);
          setStatus({ success: false, message: "Failed to send email." });
        }
      );
  };

  return (
    <div
      className="bg-cover bg-center py-32"
      id="contactus"
      style={{ backgroundImage: `url(${ContactBg})` }}
    >
      <div className="font-sans">
        <div className="contactFlex flex items-center justify-center">
          <div className="ml-5">
            <div className="font-geo contact ml-28 text-6xl mb-10 text-[#EF3B3B] text-left font-bold">
              Contact Us
            </div>
           
            <div className="flex flex-wrap justify-center items-center lg:mx-28 gap-10">
              <form ref={form} onSubmit={sendEmail} className="">
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-3 flex-wrap font-sans mt-7">
                  <input
                    type="text"
                    placeholder="Firstname*"
                    name="firstname"
                    id="firstname"
                    required
                    className="w-80 rounded-full px-4 py-3 text-black placeholder-black mr-3"
                    onChange={(e) => segment(`Entered First Name : ${e?.target?.value}`, {})}
                  ></input>
                  <input
                    type="text"
                    placeholder="Lastname*"
                    name="lastname"
                    id="lastname"
                    required
                    className="w-80 rounded-full px-4 py-3 text-black placeholder-black mr-3"
                    onChange={(e) => segment(`Entered Last Name : ${e?.target?.value}`, {})}
                  ></input>
                </div>
                <div className="justify-center grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-3 items-center font-sans mt-3 lg:mt-7">
                  <input
                    type="text"
                    name="user_email"
                    id="user_email"
                    placeholder="Email*"
                    required
                    className="w-80 rounded-full px-4 py-3 text-black placeholder-black mr-3"
                    onChange={(e) => segment(`Entered Email : ${e?.target?.value}`, {})}
                  ></input>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone*"
                    required
                    className="w-80 rounded-full px-4 py-3 text-black placeholder-black mr-3"
                    onChange={(e) => segment(`Entered Phone : ${e?.target?.value}`, {})}
                  ></input>
                </div>
                <div className="flex justify-center items-center font-sans mt-7">
                  <textarea
                    rows="6"
                    type="text"
                    id="message"
                    name="message"
                    placeholder="Write your message here !!*"
                    className="w-full rounded-lg text-black p-3 placeholder-black mr-3 flex items-center"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-white rounded-full text-[#EC8E00] text-xl font-medium font-sans px-20 mt-5 hover:bg-gray-200 py-3"
                  onClick={() => segment("Send Button Clicked", {})}
                >
                  SEND
                </button>
                 {/* Display success or error message */}
                 <div className="pt-5 ">
                 {status.message && (
              <div
                className={`text-xl font-semibold mb-5  ${
                  status.success ? "text-green-500" : "text-red-500"
                }`}
              >
                {status.message}
              </div>
            )}
                 </div>
           
              </form>
            </div>

          </div>
          {/* <div className=" ">
            <div>
              <div className="aspect-w-20">
                <iframe
                  className="w-full aspect-square lg:w-100 lg:h-70"
                  style={{ filter: "invert(90%)" }}
                  src="https://www.google.com/maps/embed?pb=...">
                </iframe>
              </div>
            </div>
            <ContactImg/>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
