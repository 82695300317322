import React, { useEffect } from 'react';
import project1 from "../Images/CorvantaProjects/project-image1.png";
import project2 from "../Images/CorvantaProjects/project-image2.png";
import project3 from "../Images/CorvantaProjects/project-image3.png";
import project4 from "../Images/CorvantaProjects/project-image4.png";
import "./Projects.scss"
import { Link } from "react-scroll";
import WOW from "wow.js";

export const Projects = () => {

    useEffect(() => {
        const wow = new WOW();
        wow.init();
      }, []);
    
    return (
        <div className='project-container font-sans 'id="projects">
            <div className="section" id="projects" style={{ textAlign: "left" }}>
                <div className="sm:px-2">
                    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center lg:mx-32 p-10 gap-8">

                        <div className="w-full flex flex-col justify-start items-start ">
                            <span className="flex justify-center flex-col  items-center">
                                <svg
                                    width="129"
                                    height="27"
                                    viewBox="0 0 129 27"
                                    fill="#bfbfbf"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="mb-6"
                                >
                                    <rect width="4" height="27" fill="#bfbfbf" />
                                    <rect
                                        x="25"
                                        width="4"
                                        height="27"
                                        fill="#bfbfbf"

                                    />
                                    <rect
                                        x="50"
                                        width="4"
                                        height="27"
                                        fill="#bfbfbf"

                                    />
                                    <rect x="75" width="4" height="27" fill="#bfbfbf" />
                                    <rect
                                        x="100"
                                        width="4"
                                        height="27"
                                        fill="#bfbfbf"

                                    />
                                    <rect
                                        x="125"
                                        width="4"
                                        height="27"
                                        fill="#bfbfbf"

                                    />
                                </svg>
                            </span>
                            <div className="text-2xl wow fadeInUp font-geo  text-[#EF3B3B] font-bold mb-2 lg:mb-6 text-left">  
                            When Apps Get Brainy, The Universe Gets Wiser <br />
                               The Marvels of AI App Development
                            </div>
                            <div className=' text-xl  wow fadeInUp text-white font-normal text-justify lg:w-3/4'>
                                <p>We are navigating the captivating world of Artificial Intelligence, where the <span className='font-semibold'>”visionary in you is beckoned”</span>.</p>  
                                <br />
                                <p className='text-lg wow slideInLeft'>Our core strength lies in crafting intelligent applications that adapt to your user behaviour, setting us apart in the crowded tech landscape. Focusing on seamless integration and personalised experiences, we redefine the standard for AI-driven solutions. Choose us for a unique blend of expertise, creativity, and user empowerment in every app we develop.
                                </p>
                                <br />
                                <p>With us, your dreams aren't just visions; they're blueprints for a brighter future.</p>
                            </div>
                            <div className='text-lg wow slideInRight text-black font-medium mt-14'>
                                <Link 
                                to="contactus"
                                spy={true}
                                smooth={true}
                                className='button py-3 px-4 hover:text-white' style={{
                                    background: "linear-gradient(to right, black 50 %, white 50 %)"
                                }}>Here is your way</Link>
                            </div>
                        </div>
                        <div className="project-image w-full lg:w-1/2 flex flex-col justify-end items-end ">
                            <div className="grid grid-cols-2 gap-6  wow slideInRight">
                                <div className="overflow-hidden">
                                    <img
                                        className="w-full rounded-l hover:scale-125 transition-all ease-in-out duration-500"
                                        src={project1}
                                        alt="project Image"
                                    />
                                </div>
                                <div className="overflow-hidden">
                                    <img
                                        className="w-full rounded-l hover:scale-125 transition-all ease-in-out duration-500"
                                        src={project2}
                                        alt="project Image"
                                    />
                                </div>
                                <div className="overflow-hidden">
                                    <img
                                        className="w-full rounded-l hover:scale-125 transition-all ease-in-out duration-500"
                                        src={project3}
                                        alt="project Image"
                                    />
                                </div>
                                <div className="overflow-hidden">
                                    <img
                                        className="w-full rounded-l hover:scale-125 transition-all ease-in-out duration-500"
                                        src={project4}
                                        alt="project Image"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
