import React from "react";
import strategize from "../Images/Strategize_icon.svg";
import Discover from "../Images/Discover_icon.svg";
import Connect from "../Images/Connect_icon.svg";
import Implement from "../Images/Implement_icon.svg";

export const EasyFlow = () => {
  return (
    <div id="easyflow" className="">
      <div className="">
        <div className="p-15 flex flex-col items-center justify-center text-white font-semibold text-center font-sans">
          <div className="mt-20">
            <span className="flex justify-center flex-col items-center">
              <svg
                width="129"
                height="27"
                viewBox="0 0 129 27"
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-6"
              >
                <rect width="4" height="27" fill="#ffffff" />
                <rect x="25" width="4" height="27" fill="#ffffff" />
                <rect x="50" width="4" height="27" fill="#ffffff" />
                <rect x="75" width="4" height="27" fill="#ffffff" />
                <rect x="100" width="4" height="27" fill="#ffffff" />
                <rect x="125" width="4" height="27" fill="#ffffff" />
              </svg>
            </span>
            <div className="text-xl text-white wow fadeInUp ">Begin with a Bang</div>
          </div>
          <div className="lg:text-6xl text-3xl font-bold wow fadeInUp  font-geo text-[#EF3B3B] mt-4 mb-5">
            Embrace the Easy Flow
          </div>
          <div className="text-xl font-semibold wow fadeInUp text-gray-400 mt-4 mb-5">
            We are your trusted partner for end-to-end software services,
            <br /> from concept to implementation
          </div>
        </div>
        <div className="flex flex-wrap justify-center font-sans ">
          {/* Icon and text sections */}
          <div className="flex flex-col items-center p-10">
            <img
              className="mx-auto mb-5 w-24 h-24 items-center justify-center transition duration-300 ease-in-out transform hover:scale-105"
              src={strategize}
              alt="strategize icon"
            />
            <div className="text-4xl font-semibold text-white text-center">
              <div>
                01
              </div>
              <div>
              Strategize <br></br>and Synthesize
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center p-10">
            <img
              className="mx-auto mb-5 w-24 h-24 items-center justify-center transition duration-300 ease-in-out transform hover:scale-105"
              src={Discover}
              alt="Discover icon"
            />
            <div className="text-4xl font-semibold text-white text-center">
              <div>
                02
              </div>
              <div>
              Discover <br></br>and Discern
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center p-10">
            <img
              className="mx-auto mb-5 w-24 h-24 items-center justify-center transition duration-300 ease-in-out transform hover:scale-105"
              src={Connect}
              alt="Connect icon"
            />
            <div className="text-4xl font-semibold text-white text-center">
              <div>
                03
              </div>
              <div>
              Connect <br></br> and Collaborate
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center p-10">
            <img
              className="mx-auto mb-5 w-24 h-24 items-center justify-center transition duration-300 ease-in-out transform hover:scale-105"
              src={Implement}
              alt="Implement icon"
            />
            <div className="text-4xl font-semibold text-white text-center">
              <div>
                04
              </div>
              <div>
              Implement <br></br> and Ignite
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EasyFlow;
