import React, { useState } from "react";
import { Link } from "react-scroll";
import { FiMenu, FiX } from "react-icons/fi";
import corvanta from "../Images/Corvanta.png";
import "./TopNavbar.scss";
import expand from "../Images/expand.svg";
import { segment } from "../../segment";

export const TopNavbar = () => {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const toggleServicesDropdown = () => {
    segment("Services Button Clicked",{})
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  };

  const openCorvantaSpendAnalysis = (e) => {
    e.preventDefault();
    segment("IT Spend Analysis Button Clicked",{})
    window.open("https://services.corvanta.io/", "_blank");
  };


  return (
    <div className="font-sans">
      <div className="flex justify-center mt-3 sticky top-10 left-0 right-0">
        <div className="px-10 bg-[#141414] shadow-xl rounded-3xl py-5">
          <div className="container mx-auto flex justify-between items-center px-5 py-3 space-x-10">
            <div className="text-white  corvanta-logo">
              <img
                src={corvanta}
                className="lg:h-16 sm:h-7 lg:w-auto sm:w-48"
                alt="corvanta"
              />
            </div>
            {/* Toggle button for mobile menu */}
            <button
              onClick={toggleMobileMenu}
              className="block lg:hidden text-white focus:outline-none"
            >
              {isMobileMenuOpen ? <FiX /> : <FiMenu />}
            </button>
            {/* Navigation links */}
            <nav className="hidden lg:flex  cursor-pointer text-sm font-semibold justify-between">

              <Link
                to="home"
                spy={true}
                smooth={true}
                className="text-white hover:text-gray-300 mx-4"
                onClick={()=>segment("Home Button Clicked",{})}
              >
                Home
              </Link>

              <div className="relative group">
                <div className="flex gap-1  mx-4" onClick={toggleServicesDropdown}>
                  <button
                    className="text-white hover:text-gray-300 flex "
                  >
                    Services
                  </button>
                  <img src={expand} alt="" />
                </div>

                {isServicesDropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-[#353535] rounded-lg shadow-lg z-20">
                    <a
                      href="#"
                      className="block px-4 py-2 text-white hover:text-gray-300"
                      onClick={openCorvantaSpendAnalysis}
                    >
                      IT Spend Analysis
                    </a>
                  </div>
                )}
              </div>
             
              <Link
                to="easyflow"
                spy={true}
                smooth={true}
                className="text-white hover:text-gray-300 mx-4"
                onClick={()=>segment("Easy process Button Clicked",{})}
              >
                Easy process
              </Link>
              <Link
                to="whycorvanta"
                spy={true}
                smooth={true}
                className="text-white hover:text-gray-300 mx-4"
                onClick={()=>segment("About Us Button Clicked",{})}
              >
                About Us
              </Link>
              <Link
                to="products"
                spy={true}
                smooth={true}
                className="text-white hover:text-gray-300 mx-4"
                onClick={()=>segment("Products Cliked",{})}
              >
                Products
              </Link>
              <Link
                to="nearfuture"
                spy={true}
                smooth={true}
                className="text-white hover:text-gray-300 mx-4"
                onClick={()=>segment("Near Future Button Clicked",{})}
              >
                Near Future
              </Link>
            </nav>
            {/* Mobile popover menu */}
            {isMobileMenuOpen && (
              <div className="lg:hidden mt-10 absolute top-16 left-0 cursor-pointer right-10 flex justify-center bg-[#0e0e0e] rounded-3xl p-10 z-10">
                <div className="flex flex-col space-y-10 cursor-pointer">
                  <Link
                    to="home"
                    spy={true}
                    smooth={true}
                    className="text-white hover:text-gray-300"
                    onClick={() => { closeMobileMenu(); segment(); }}
                  >
                    Home
                  </Link>

                  <div className="relative group">
                    <div className="flex gap-1" onClick={toggleServicesDropdown}>
                      <button
                        className="text-white hover:text-gray-300 "
                      >
                        Services
                      </button>
                      <img src={expand} alt="" />
                    </div>

                    {isServicesDropdownOpen && (
                      <div className="absolute left-0 mt-2 w-72 bg-[#353535] rounded-lg shadow-lg z-20">
                        <a
                          href="#"
                          className="block px-4 py-2 text-white hover:text-gray-300"
                          onClick={openCorvantaSpendAnalysis}
                        >
                          IT Spend Analysis
                        </a>
                      </div>
                    )}
                  </div>
                
                  <Link
                    to="easyflow"
                    spy={true}
                    smooth={true}
                    className="text-white hover:text-gray-300"
                    onClick={closeMobileMenu}
                  >
                    Easy process
                  </Link>
                  <Link
                    to="whycorvanta"
                    spy={true}
                    smooth={true}
                    className="text-white hover:text-gray-300"
                    onClick={closeMobileMenu}
                  >
                    About Us
                  </Link>
                  <Link
                    to="products"
                    spy={true}
                    smooth={true}
                    className="text-white hover:text-gray-300"
                    onClick={()=>segment("Products Cliked",{})}
                  >
                    Products
                  </Link>
                  <Link
                    to="nearfuture"
                    spy={true}
                    smooth={true}
                    className="text-white hover:text-gray-300"
                    onClick={closeMobileMenu}
                  >
                    Near Future
                  </Link>
                  <Link
                    to="contactus"
                    spy={true}
                    smooth={true}
                    className="text-white hover:text-gray-300"
                    onClick={closeMobileMenu}
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            )}
            <div className="wow fadeInUp hidden lg:block">
              <Link
                to="contactus"
                spy={true}
                smooth={true}
                className="btn hidden lg:block font-sans text-black cursor-pointer bg-[#202020] rounded-lg px-8 py-2 hover:text-white"
                onClick={()=>segment("Get Started Button Clicked",{})}
                // style={{ background: "linear-gradient(to left, black 50%, white 50%)" }}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
