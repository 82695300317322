import React from "react";

const languages = [
  { name: 'Python', imgSrc: require("../Images/language/python.png") },
  { name: 'React', imgSrc: require("../Images/language/React.png") },
  { name: 'NodeJs', imgSrc: require("../Images/language/NodeJs.png") },
  { name: 'AWS', imgSrc: require("../Images/language/aws_icon_146074.png") },
  { name: 'LangChain', imgSrc: require("../Images/language/langchnain.png") },
  { name: 'Swift Ui', imgSrc: require("../Images/language/swiftui.png") },
  { name: 'JavaScript', imgSrc: require("../Images/language/JavaScript.png") },
  { name: 'CSS3', imgSrc: require("../Images/language/CSS3.png") }, 
  { name: 'Java', imgSrc: require("../Images/language/Java.png") },
  { name: 'SpringBoot', imgSrc: require("../Images/language/SpringBoot.png") },
  { name: 'Flutter', imgSrc: require("../Images/language/Flutter.png") },
  { name: 'RestAPI', imgSrc: require("../Images/language/RestAPI.png") },
  { name: 'Postman', imgSrc: require("../Images/language/Postman.png") },
  { name: 'MySQL', imgSrc: require("../Images/language/MySQL.png") },
  { name: 'MongoDB', imgSrc: require("../Images/language/MongoDB.png") }, 
  { name: 'Shopify', imgSrc: require("../Images/language/Shopify.png") }
];

const Language = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen mt-8">
      <div className="text-center px-4">
        <h2 className="text-3xl md:text-5xl font-geo text-[#EF3B3B] font-bold mb-4  lg:mx-10 tracking-wide wow fadeInUp">
          Powering Your Projects with Top Notch Technology Expertise
        </h2>
        <p className="text-lg md:text-xl font-semibold wow fadeInUp text-gray-400 mt-4 mb-8 mx-14">
          Along with ReactJS, we offer end-to-end full-stack development expertise. Our React developers have top-of-the-line experience in working with the latest front-end, back-end technologies, servers, clouds, and databases.
        </p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 wow fadeInUp md:grid-cols-4 lg:grid-cols-8 gap-16 px-4">
        {languages.map((language, index) => (
          <div key={index} className="p-3 flex justify-center items-center">
            <div className="icon icon-lg text-primary">
              <img
                // className="language-img"
                src={language.imgSrc}
                alt={language.name}
                className={language.className}
                style={{ height: "80px", width: language.name === 'LangChain' ? "100px" : "80px" }}
              />
               <div className="mt-3 text-gray-200 text-lg font-semibold">
                {language.name}
            </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Language;
