import React from "react";
import { Link } from "react-scroll";
// import "./Product.scss";
import accostayImage from "../Images/accostay.png";
import { segment } from "../../segment";

export const Product = () => {
  return (
    <div id="products" className="p-5 lg:p-1 mb-10" style={{ backgroundImage: `url(${""})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {/* New Centered Heading */}
      <div className="text-center mb-10">
      <div className="flex justify-left flex-col items-center">
            <svg
              width="129"
              height="27"
              viewBox="0 0 129 27"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              className="mb-6"
            >
              <rect width="4" height="27" fill="#ffffff" />
              <rect x="25" width="4" height="27" fill="#ffffff" />
              <rect x="50" width="4" height="27" fill="#ffffff" />
              <rect x="75" width="4" height="27" fill="#ffffff" />
              <rect x="100" width="4" height="27" fill="#ffffff" />
              <rect x="125" width="4" height="27" fill="#ffffff" />
            </svg>
          </div>

        <h2 className="text-3xl lg:text-5xl font-geo font-bold text-[#EF3B3B] ">Our Products</h2>
      </div>
      
      <div className="p-15 flex flex-col lg:flex-row lg:justify-between lg:items-center text-white font-semibold font-sans">
        
        {/* Left Content */}
        <div className="lg:w-1/2 text-left lg:ml-28 mt-20">
         
          <div className="lg:text-4xl text-2xl font-geo font-bold text-[#EF3B3B] mb-5">
            Transform property Management with AccoStay
          </div>
          <div className="text-white font-medium lg:text-xl text-lg mx-4 wow slideInLeft">
            AccoStay simplifies hostel management with an all-in-one solution, offering seamless tenant management, automated rent collection, and real-time analytics. Whether you're a hostel owner or property manager, reduces manual work, and improves tenant experience. Manage properties effortlessly and scale your business with ease.
          </div>
          <div className='text-lg mt-14'>
            <a
              href="https://accostay.com"
              onClick={()=>segment("AccoStay link clicked",{})}
              target="_blank"
              rel="noopener noreferrer"
              className='button py-3 px-4 hover:text-white' style={{
                background: "bg-white"
              }}
            >Explore AccoStay</a>
          </div>
        </div>
        
        {/* Right Image */}
        <div className="lg:w-1/2 flex justify-center mt-10 lg:mt-0 lg:mr-28 wow slideInRight">
          <img src={accostayImage} alt="AccoStay" className="w-full lg:w-auto" />
        </div>
      </div>
    </div>
  );
};

export default Product;
